.skills {
  min-height: 40vh;
  display: flex;
  align-items: center;
  background-color: #000;
}
.skills > p {
  font-size: 2rem;
  line-height: 3rem;
  padding: 0 3rem;
  max-width: 1000px;
  margin: 0 auto;
  color: #fff;
}
/* MEDIA QUERIES */
/* tablet */
@media (max-width: 800px) {
  .skills > p {
    padding: 3rem 1rem;
    margin: 0 3rem;
    font-size: 1.75rem;
    line-height: 2.25rem;
  }
}
/* mobile */
@media (max-width: 600px) {
  .skills > p {
    margin: 0 0.5rem;
  }
}
