.footer {
  text-align: center;
  padding: 1rem 0;
  line-height: 2.5rem;
  font-size: 1.25rem;
}
.footer a {
  color: inherit;
}
/* MEDIA QUERIES */
/* tablet */
@media (max-width: 800px) {
  .footer {
      font-size: 1.5rem;
      line-height: 3rem;
  }
}

