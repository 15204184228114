.header {
  min-height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navbar {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}
.navbar a {
  text-decoration: none;
  color: #fff;
  font-size: 1.5rem;
}
.theme-toggle {
    margin-left: 10px;
    width: 65px;
    height: 35px;
    background-color: #fe9677;
    padding: 1px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: flex-start;
    transition: justify-content 2s;
  }
  .toggled {
    justify-content: flex-end;
  }
  .toggle {
    background-color: #26324f;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
/* MEDIA QUERIES */
/* tablet */
@media (max-width: 800px) {
    .navbar a {
        font-size: 1.25rem;
        margin: 1rem;
    }
}
/* mobile */
@media (max-width: 600px) {
    .header {
        min-height: 15vh;
    }
}

