.home {
  position: relative;
  height: 85vh;
  width: 100%;
  overflow: hidden;
}
.mountain1 {
  position: absolute;
  left: 0;
  bottom: 10vh;
  width: 100%;
  height: auto;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  -webkit-mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0)
  );
}
.mountain2 {
  position: absolute;
  transform: scaleX(-1) !important;
  left: 0;
  bottom: -0.5vh;
  width: 100%;
  height: auto;
}
h1 {
  position: absolute;
  top: 40vh;
  width: 100%;
  padding: 0 2rem;
  text-align: center;
  color: white;
  font-size: 4rem;
}
.star {
  position: absolute;
  background: #fff;
  border-radius: 50%;
  animation: animate linear infinite;
}
@keyframes animate {
  0% {
    opacity: 0;
    transform: translateX(0);
  }
  25%,
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(-10px);
  }
}

/* MEDIA QUERIES */
/* tablet */
@media (max-width: 800px) {
  h1 {
    font-size: 3.5rem;
  }
  .mountain1 {
    bottom: 5vh;
  }
}
/* mobile */
@media (max-width: 600px) {
  .home {
    height: 80vh;
  }
  h1 {
    font-size: 2.5rem;
    line-height: 3rem;
  }
  .mountain1 {
    bottom: 3vh;
  }
}
